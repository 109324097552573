/* tslint:disable */
/* eslint-disable */
/**
 * Encephalon
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DetailedStudy
 */
export interface DetailedStudy {
    /**
     * 
     * @type {string}
     * @memberof DetailedStudy
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedStudy
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedStudy
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<Dicom>}
     * @memberof DetailedStudy
     */
    'dicoms': Array<Dicom>;
    /**
     * 
     * @type {Array<Scan>}
     * @memberof DetailedStudy
     */
    'scans': Array<Scan>;
}
/**
 * 
 * @export
 * @interface DiameterConclusion
 */
export interface DiameterConclusion {
    /**
     * 
     * @type {Array<MinimalDicom>}
     * @memberof DiameterConclusion
     */
    'dicoms': Array<MinimalDicom>;
    /**
     * 
     * @type {Metric}
     * @memberof DiameterConclusion
     */
    'measurement': Metric;
    /**
     * 
     * @type {number}
     * @memberof DiameterConclusion
     */
    'value'?: number | null;
    /**
     * 
     * @type {Array<DiameterPrediction>}
     * @memberof DiameterConclusion
     */
    'diameters'?: Array<DiameterPrediction>;
    /**
     * 
     * @type {string}
     * @memberof DiameterConclusion
     */
    'flag'?: DiameterConclusionFlagEnum;
}

export const DiameterConclusionFlagEnum = {
    High: 'HIGH',
    Low: 'LOW'
} as const;

export type DiameterConclusionFlagEnum = typeof DiameterConclusionFlagEnum[keyof typeof DiameterConclusionFlagEnum];

/**
 * 
 * @export
 * @interface DiameterOutsideRangeError
 */
export interface DiameterOutsideRangeError {
    /**
     * 
     * @type {string}
     * @memberof DiameterOutsideRangeError
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof DiameterOutsideRangeError
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof DiameterOutsideRangeError
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DiameterPrediction
 */
export interface DiameterPrediction {
    /**
     * 
     * @type {string}
     * @memberof DiameterPrediction
     */
    'dicom'?: string;
    /**
     * 
     * @type {Frame}
     * @memberof DiameterPrediction
     */
    'frame'?: Frame;
    /**
     * 
     * @type {Array<KeypointPrediction>}
     * @memberof DiameterPrediction
     */
    'keypoint_predictions'?: Array<KeypointPrediction>;
    /**
     * 
     * @type {File}
     * @memberof DiameterPrediction
     */
    'visualization'?: File | null;
}
/**
 * 
 * @export
 * @interface Dicom
 */
export interface Dicom {
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'study': string;
    /**
     * 
     * @type {File}
     * @memberof Dicom
     */
    'file': File;
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Dicom
     */
    'delta_x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dicom
     */
    'delta_y'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'media'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dicom
     */
    'sop_instance_uid'?: string | null;
}
/**
 * 
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {Array<LowQualityDicom>}
     * @memberof Errors
     */
    'low_quality': Array<LowQualityDicom>;
    /**
     * 
     * @type {Array<ViewportNotFound>}
     * @memberof Errors
     */
    'viewport_not_found': Array<ViewportNotFound>;
    /**
     * 
     * @type {Array<DiameterOutsideRangeError>}
     * @memberof Errors
     */
    'diameter_outside_range': Array<DiameterOutsideRangeError>;
    /**
     * 
     * @type {Array<GenericError>}
     * @memberof Errors
     */
    'other': Array<GenericError>;
}
/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Frame
 */
export interface Frame {
    /**
     * 
     * @type {number}
     * @memberof Frame
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof Frame
     */
    'file'?: string;
    /**
     * 
     * @type {number}
     * @memberof Frame
     */
    'delta_x': number;
    /**
     * 
     * @type {number}
     * @memberof Frame
     */
    'delta_y': number;
}
/**
 * 
 * @export
 * @interface GenericError
 */
export interface GenericError {
    /**
     * 
     * @type {string}
     * @memberof GenericError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface KeypointPrediction
 */
export interface KeypointPrediction {
    /**
     * 
     * @type {number}
     * @memberof KeypointPrediction
     */
    'coordinate_x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KeypointPrediction
     */
    'coordinate_y'?: number | null;
}
/**
 * 
 * @export
 * @interface ListDetailedStudys200Response
 */
export interface ListDetailedStudys200Response {
    /**
     * 
     * @type {number}
     * @memberof ListDetailedStudys200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDetailedStudys200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListDetailedStudys200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<DetailedStudy>}
     * @memberof ListDetailedStudys200Response
     */
    'results'?: Array<DetailedStudy>;
}
/**
 * 
 * @export
 * @interface ListDicoms200Response
 */
export interface ListDicoms200Response {
    /**
     * 
     * @type {number}
     * @memberof ListDicoms200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListDicoms200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListDicoms200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Dicom>}
     * @memberof ListDicoms200Response
     */
    'results'?: Array<Dicom>;
}
/**
 * 
 * @export
 * @interface ListPublicOpenAPICompatibleReports200Response
 */
export interface ListPublicOpenAPICompatibleReports200Response {
    /**
     * 
     * @type {number}
     * @memberof ListPublicOpenAPICompatibleReports200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListPublicOpenAPICompatibleReports200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListPublicOpenAPICompatibleReports200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Report>}
     * @memberof ListPublicOpenAPICompatibleReports200Response
     */
    'results'?: Array<Report>;
}
/**
 * 
 * @export
 * @interface ListScans200Response
 */
export interface ListScans200Response {
    /**
     * 
     * @type {number}
     * @memberof ListScans200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListScans200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListScans200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Scan>}
     * @memberof ListScans200Response
     */
    'results'?: Array<Scan>;
}
/**
 * 
 * @export
 * @interface ListStudys200Response
 */
export interface ListStudys200Response {
    /**
     * 
     * @type {number}
     * @memberof ListStudys200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListStudys200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListStudys200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Study>}
     * @memberof ListStudys200Response
     */
    'results'?: Array<Study>;
}
/**
 * 
 * @export
 * @interface ListTokens200Response
 */
export interface ListTokens200Response {
    /**
     * 
     * @type {number}
     * @memberof ListTokens200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTokens200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListTokens200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Token>}
     * @memberof ListTokens200Response
     */
    'results'?: Array<Token>;
}
/**
 * 
 * @export
 * @interface ListTools200Response
 */
export interface ListTools200Response {
    /**
     * 
     * @type {number}
     * @memberof ListTools200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTools200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListTools200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Tool>}
     * @memberof ListTools200Response
     */
    'results'?: Array<Tool>;
}
/**
 * 
 * @export
 * @interface ListWebhooks200Response
 */
export interface ListWebhooks200Response {
    /**
     * 
     * @type {number}
     * @memberof ListWebhooks200Response
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListWebhooks200Response
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListWebhooks200Response
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Webhook>}
     * @memberof ListWebhooks200Response
     */
    'results'?: Array<Webhook>;
}
/**
 * 
 * @export
 * @interface LowQualityDicom
 */
export interface LowQualityDicom {
    /**
     * 
     * @type {MinimalDicom}
     * @memberof LowQualityDicom
     */
    'dicom': MinimalDicom;
    /**
     * 
     * @type {string}
     * @memberof LowQualityDicom
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'units': string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'acronym': string;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'low_range': number;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'high_range': number;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'low_range_male'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'high_range_male'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'low_range_female'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    'high_range_female'?: number | null;
}
/**
 * 
 * @export
 * @interface MinimalDicom
 */
export interface MinimalDicom {
    /**
     * 
     * @type {string}
     * @memberof MinimalDicom
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface Pathology
 */
export interface Pathology {
    /**
     * 
     * @type {string}
     * @memberof Pathology
     */
    'value': string;
    /**
     * 
     * @type {Feature}
     * @memberof Pathology
     */
    'feature'?: Feature;
}
/**
 * 
 * @export
 * @interface PathologyConclusion
 */
export interface PathologyConclusion {
    /**
     * 
     * @type {Array<MinimalDicom>}
     * @memberof PathologyConclusion
     */
    'dicoms': Array<MinimalDicom>;
    /**
     * 
     * @type {Stratification}
     * @memberof PathologyConclusion
     */
    'stratification': Stratification;
    /**
     * 
     * @type {number}
     * @memberof PathologyConclusion
     */
    'score'?: number | null;
    /**
     * 
     * @type {Pathology}
     * @memberof PathologyConclusion
     */
    'pathology'?: Pathology;
    'pathology_output'?: string | null;
}
/**
 * 
 * @export
 * @interface Perspective
 */
export interface Perspective {
    /**
     * 
     * @type {Viewport}
     * @memberof Perspective
     */
    'viewport'?: Viewport;
}
/**
 * 
 * @export
 * @interface PrivateOpenAPIReport
 */
export interface PrivateOpenAPIReport {
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'created_at'?: string;
    /**
     * 
     * @type {Study}
     * @memberof PrivateOpenAPIReport
     */
    'study'?: Study;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'scan'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'file'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof PrivateOpenAPIReport
     */
    'conclusions'?: string | null;
    /**
     * 
     * @type {Array<ReportDicom>}
     * @memberof PrivateOpenAPIReport
     */
    'dicoms'?: Array<ReportDicom>;
    /**
     * 
     * @type {Array<RegressionConclusion>}
     * @memberof PrivateOpenAPIReport
     */
    'regression_measurements'?: Array<RegressionConclusion>;
    /**
     * 
     * @type {Array<DiameterConclusion>}
     * @memberof PrivateOpenAPIReport
     */
    'diameter_measurements'?: Array<DiameterConclusion>;
    /**
     * 
     * @type {Array<SegmentationConclusion>}
     * @memberof PrivateOpenAPIReport
     */
    'segmentation_measurements'?: Array<SegmentationConclusion>;
    /**
     * 
     * @type {Errors}
     * @memberof PrivateOpenAPIReport
     */
    'warnings'?: Errors;
    /**
     * 
     * @type {Array<ReportConclussion>}
     * @memberof PrivateOpenAPIReport
     */
    'enumerated_conclusions': Array<ReportConclussion>;
    /**
     * 
     * @type {Array<PathologyConclusion>}
     * @memberof PrivateOpenAPIReport
     */
    'pathology_conclusions'?: Array<PathologyConclusion>;
}
/**
 * 
 * @export
 * @interface PublicOpenAPIReport
 */
export interface PublicOpenAPIReport {
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'created_at'?: string;
    /**
     * 
     * @type {Study}
     * @memberof PublicOpenAPIReport
     */
    'study'?: Study;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'scan'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'file'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOpenAPIReport
     */
    'conclusions'?: string | null;
    /**
     * 
     * @type {Array<ReportDicom>}
     * @memberof PublicOpenAPIReport
     */
    'dicoms'?: Array<ReportDicom>;
    /**
     * 
     * @type {Array<RegressionConclusion>}
     * @memberof PublicOpenAPIReport
     */
    'regression_measurements'?: Array<RegressionConclusion>;
    /**
     * 
     * @type {Array<DiameterConclusion>}
     * @memberof PublicOpenAPIReport
     */
    'diameter_measurements'?: Array<DiameterConclusion>;
    /**
     * 
     * @type {Array<SegmentationConclusion>}
     * @memberof PublicOpenAPIReport
     */
    'segmentation_measurements'?: Array<SegmentationConclusion>;
    /**
     * 
     * @type {Errors}
     * @memberof PublicOpenAPIReport
     */
    'warnings'?: Errors;
    /**
     * 
     * @type {Array<ReportConclussion>}
     * @memberof PublicOpenAPIReport
     */
    'enumerated_conclusions': Array<ReportConclussion>;
}
/**
 * 
 * @export
 * @interface Quality
 */
export interface Quality {
    /**
     * 
     * @type {string}
     * @memberof Quality
     */
    'quantifiability'?: string;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'last_name': string | null;
}
/**
 * 
 * @export
 * @interface RegressionConclusion
 */
export interface RegressionConclusion {
    /**
     * 
     * @type {Array<MinimalDicom>}
     * @memberof RegressionConclusion
     */
    'dicoms': Array<MinimalDicom>;
    /**
     * 
     * @type {Metric}
     * @memberof RegressionConclusion
     */
    'measurement': Metric;
    /**
     * 
     * @type {number}
     * @memberof RegressionConclusion
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RegressionConclusion
     */
    'flag'?: RegressionConclusionFlagEnum;
}

export const RegressionConclusionFlagEnum = {
    High: 'HIGH',
    Low: 'LOW'
} as const;

export type RegressionConclusionFlagEnum = typeof RegressionConclusionFlagEnum[keyof typeof RegressionConclusionFlagEnum];

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'study'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'scan'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'file'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'conclusions'?: string | null;
}
/**
 * 
 * @export
 * @interface ReportConclussion
 */
export interface ReportConclussion {
    /**
     * 
     * @type {number}
     * @memberof ReportConclussion
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof ReportConclussion
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ReportDicom
 */
export interface ReportDicom {
    /**
     * 
     * @type {string}
     * @memberof ReportDicom
     */
    'uuid'?: string;
    /**
     * 
     * @type {File}
     * @memberof ReportDicom
     */
    'dicom'?: File;
    /**
     * 
     * @type {string}
     * @memberof ReportDicom
     */
    'name'?: string;
    /**
     * 
     * @type {Quality}
     * @memberof ReportDicom
     */
    'quality': Quality;
    /**
     * 
     * @type {Perspective}
     * @memberof ReportDicom
     */
    'perspective': Perspective;
    /**
     * 
     * @type {string}
     * @memberof ReportDicom
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDicom
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDicom
     */
    'media'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDicom
     */
    'sop_instance_uid'?: string;
}
/**
 * 
 * @export
 * @interface Scan
 */
export interface Scan {
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'study': string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'report'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'status'?: ScanStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'start_inference_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'end_inference_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'total_inference_time'?: string;
    /**
     * 
     * @type {number}
     * @memberof Scan
     */
    'number_of_available_dicoms'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Scan
     */
    'number_of_dicoms_scanned'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scan
     */
    'version'?: string | null;
}

export const ScanStatusEnum = {
    Pending: 'PENDING',
    Started: 'STARTED',
    Completed: 'COMPLETED',
    Failed: 'FAILED'
} as const;

export type ScanStatusEnum = typeof ScanStatusEnum[keyof typeof ScanStatusEnum];

/**
 * 
 * @export
 * @interface SegmentationConclusion
 */
export interface SegmentationConclusion {
    /**
     * 
     * @type {Array<SegmentationPrediction>}
     * @memberof SegmentationConclusion
     */
    'predictions'?: Array<SegmentationPrediction>;
    /**
     * 
     * @type {Metric}
     * @memberof SegmentationConclusion
     */
    'measurement': Metric;
    /**
     * 
     * @type {number}
     * @memberof SegmentationConclusion
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentationConclusion
     */
    'flag'?: SegmentationConclusionFlagEnum;
}

export const SegmentationConclusionFlagEnum = {
    High: 'HIGH',
    Low: 'LOW'
} as const;

export type SegmentationConclusionFlagEnum = typeof SegmentationConclusionFlagEnum[keyof typeof SegmentationConclusionFlagEnum];

/**
 * 
 * @export
 * @interface SegmentationData
 */
export interface SegmentationData {
    /**
     * 
     * @type {string}
     * @memberof SegmentationData
     */
    'type': SegmentationDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SegmentationData
     */
    'point_1_coordinate_x': number;
    /**
     * 
     * @type {number}
     * @memberof SegmentationData
     */
    'point_1_coordinate_y': number;
    /**
     * 
     * @type {number}
     * @memberof SegmentationData
     */
    'point_2_coordinate_x': number;
    /**
     * 
     * @type {number}
     * @memberof SegmentationData
     */
    'point_2_coordinate_y': number;
}

export const SegmentationDataTypeEnum = {
    Centerline: 'CENTERLINE',
    Disk: 'DISK'
} as const;

export type SegmentationDataTypeEnum = typeof SegmentationDataTypeEnum[keyof typeof SegmentationDataTypeEnum];

/**
 * 
 * @export
 * @interface SegmentationPrediction
 */
export interface SegmentationPrediction {
    /**
     * 
     * @type {MinimalDicom}
     * @memberof SegmentationPrediction
     */
    'dicom'?: MinimalDicom;
    /**
     * 
     * @type {Frame}
     * @memberof SegmentationPrediction
     */
    'frame'?: Frame;
    /**
     * 
     * @type {Array<SegmentationData>}
     * @memberof SegmentationPrediction
     */
    'lines'?: Array<SegmentationData>;
}
/**
 * 
 * @export
 * @interface Stratification
 */
export interface Stratification {
    /**
     * 
     * @type {string}
     * @memberof Stratification
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Stratification
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Study
 */
export interface Study {
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Study
     */
    'age'?: number;
    /**
     * 
     * @type {number}
     * @memberof Study
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Study
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'sex'?: StudySexEnum;
}

export const StudySexEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type StudySexEnum = typeof StudySexEnum[keyof typeof StudySexEnum];

/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'refresh': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'access'?: string;
}
/**
 * 
 * @export
 * @interface Tool
 */
export interface Tool {
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'ext_uuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tool
     */
    'key'?: string | null;
    /**
     * 
     * @type {ToolDefaultPipeline}
     * @memberof Tool
     */
    'default_pipeline'?: ToolDefaultPipeline;
}
/**
 * 
 * @export
 * @interface ToolDefaultPipeline
 */
export interface ToolDefaultPipeline {
    /**
     * 
     * @type {string}
     * @memberof ToolDefaultPipeline
     */
    'ext_uuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToolDefaultPipeline
     */
    'key'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    'is_superuser': boolean;
}
/**
 * 
 * @export
 * @interface VersionIdentifier
 */
export interface VersionIdentifier {
    /**
     * 
     * @type {string}
     * @memberof VersionIdentifier
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface Viewport
 */
export interface Viewport {
    /**
     * 
     * @type {string}
     * @memberof Viewport
     */
    'window': string;
    /**
     * 
     * @type {string}
     * @memberof Viewport
     */
    'view': string;
}
/**
 * 
 * @export
 * @interface ViewportNotFound
 */
export interface ViewportNotFound {
    /**
     * 
     * @type {Viewport}
     * @memberof ViewportNotFound
     */
    'viewport': Viewport;
    /**
     * 
     * @type {string}
     * @memberof ViewportNotFound
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    'token'?: string;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetailedStudy: async (detailedStudy?: DetailedStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/detailed_studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detailedStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} study 
         * @param {File} file 
         * @param {string} [uuid] 
         * @param {string} [createdAt] 
         * @param {string} [name] 
         * @param {number} [deltaX] 
         * @param {number} [deltaY] 
         * @param {string} [media] 
         * @param {string} [sopInstanceUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicom: async (study: string, file: File, uuid?: string, createdAt?: string, name?: string, deltaX?: number, deltaY?: number, media?: string, sopInstanceUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'study' is not null or undefined
            assertParamExists('createDicom', 'study', study)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createDicom', 'file', file)
            const localVarPath = `/api/v2/dicoms/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (uuid !== undefined) { 
                localVarFormParams.append('uuid', uuid as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('created_at', createdAt as any);
            }
    
            if (study !== undefined) { 
                localVarFormParams.append('study', study as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (deltaX !== undefined) { 
                localVarFormParams.append('delta_x', deltaX as any);
            }
    
            if (deltaY !== undefined) { 
                localVarFormParams.append('delta_y', deltaY as any);
            }
    
            if (media !== undefined) { 
                localVarFormParams.append('media', media as any);
            }
    
            if (sopInstanceUid !== undefined) { 
                localVarFormParams.append('sop_instance_uid', sopInstanceUid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalStudy: async (study?: Study, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(study, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Scan} [scan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan: async (scan?: Scan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/scans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudy: async (study?: Study, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(study, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (token?: Token, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenRefresh: async (tokenRefresh?: TokenRefresh, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (register?: Register, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/user_registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook: async (webhook?: Webhook, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/webhook/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhook, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createheartlabWebhookRequestHandler: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/integrations/heartlab/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyDetailedStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyDetailedStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/detailed_studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyDicom: async (uuid: string, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyDicom', 'uuid', uuid)
            const localVarPath = `/api/v2/dicoms/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInternalStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyInternalStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyScan: async (uuid: string, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyScan', 'uuid', uuid)
            const localVarPath = `/api/v2/scans/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyToken', 'id', id)
            const localVarPath = `/api/v2/internal/token/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyWebhook: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('destroyWebhook', 'uuid', uuid)
            const localVarPath = `/api/v2/webhook/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetailedStudys: async (page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/detailed_studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDicoms: async (page?: number, pageSize?: number, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/dicoms/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInternalStudys: async (page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrivateOpenAPICompatibleReports: async (page?: number, pageSize?: number, study?: string, scan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/report/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }

            if (scan !== undefined) {
                localVarQueryParameter['scan'] = scan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicOpenAPICompatibleReports: async (page?: number, pageSize?: number, study?: string, scan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }

            if (scan !== undefined) {
                localVarQueryParameter['scan'] = scan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRetryExtractions: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/retry_study_extraction/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScans: async (page?: number, pageSize?: number, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/scans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudys: async (page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

            /**
     *
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {string} [q] search
     * @param {string} [name] name
     * @param {string} [uuid] uuid
     * @param {string} [createdAtGte] created_at__gte
     * @param {string} [createdAtLte] created_at__lte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllStudies: async (
      page?: number,
      pageSize?: number,
      q?: string,
      name?: string,
      uuid?: string,
      createdAtGte?: string,
      createdAtLte?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/all_studies/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      if (uuid !== undefined) {
        localVarQueryParameter["uuid"] = uuid;
      }

      if (createdAtGte !== undefined) {
        localVarQueryParameter["created_at__gte"] = createdAtGte;
      }

      if (createdAtLte !== undefined) {
        localVarQueryParameter["created_at__lte"] = createdAtLte;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokens: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/tools/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebhooks: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/webhook/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listhtmlReports: async (reportUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportUuid' is not null or undefined
            assertParamExists('listhtmlReports', 'reportUuid', reportUuid)
            const localVarPath = `/api/v2/reports/{report_uuid}/html/`
                .replace(`{${"report_uuid"}}`, encodeURIComponent(String(reportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDetailedStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, detailedStudy?: DetailedStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partialUpdateDetailedStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/detailed_studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detailedStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInternalStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partialUpdateInternalStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(study, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partialUpdateStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(study, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateToken: async (id: string, token?: Token, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateToken', 'id', id)
            const localVarPath = `/api/v2/internal/token/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWebhook: async (uuid: string, webhook?: Webhook, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('partialUpdateWebhook', 'uuid', uuid)
            const localVarPath = `/api/v2/webhook/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhook, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDetailedStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveDetailedStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/detailed_studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDicom: async (uuid: string, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveDicom', 'uuid', uuid)
            const localVarPath = `/api/v2/dicoms/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmpty: async (reportUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportUuid' is not null or undefined
            assertParamExists('retrieveEmpty', 'reportUuid', reportUuid)
            const localVarPath = `/api/v2/internal/inferencing/regenerate/{report_uuid}/`
                .replace(`{${"report_uuid"}}`, encodeURIComponent(String(reportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inferenceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInferenceReport: async (inferenceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inferenceUuid' is not null or undefined
            assertParamExists('retrieveInferenceReport', 'inferenceUuid', inferenceUuid)
            const localVarPath = `/api/v2/internal/inferencing/html_report/{inference_uuid}/`
                .replace(`{${"inference_uuid"}}`, encodeURIComponent(String(inferenceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInternalStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveInternalStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePrivateOpenAPICompatibleReport: async (uuid: string, study?: string, scan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrievePrivateOpenAPICompatibleReport', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/report/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }

            if (scan !== undefined) {
                localVarQueryParameter['scan'] = scan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicOpenAPICompatibleReport: async (uuid: string, study?: string, scan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrievePublicOpenAPICompatibleReport', 'uuid', uuid)
            const localVarPath = `/api/v2/reports/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }

            if (scan !== undefined) {
                localVarQueryParameter['scan'] = scan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRetryExtraction: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveRetryExtraction', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/retry_study_extraction/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveScan: async (uuid: string, study?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveScan', 'uuid', uuid)
            const localVarPath = `/api/v2/scans/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStudy: async (uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveStudy', 'uuid', uuid)
            const localVarPath = `/api/v2/studies/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (uuid2 !== undefined) {
                localVarQueryParameter['uuid'] = uuid2;
            }

            if (createdAtGte !== undefined) {
                localVarQueryParameter['created_at__gte'] = createdAtGte;
            }

            if (createdAtLte !== undefined) {
                localVarQueryParameter['created_at__lte'] = createdAtLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTestWebhook: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveTestWebhook', 'uuid', uuid)
            const localVarPath = `/api/v2/internal/test_webhook/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveToken', 'id', id)
            const localVarPath = `/api/v2/internal/token/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTool: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveTool', 'id', id)
            const localVarPath = `/api/v2/internal/tools/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/internal/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVersionIdentifier: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWebhook: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('retrieveWebhook', 'uuid', uuid)
            const localVarPath = `/api/v2/webhook/{uuid}/`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken: async (id: string, token?: Token, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateToken', 'id', id)
            const localVarPath = `/api/v2/internal/token/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDetailedStudy(detailedStudy?: DetailedStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedStudy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDetailedStudy(detailedStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} study 
         * @param {File} file 
         * @param {string} [uuid] 
         * @param {string} [createdAt] 
         * @param {string} [name] 
         * @param {number} [deltaX] 
         * @param {number} [deltaY] 
         * @param {string} [media] 
         * @param {string} [sopInstanceUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDicom(study: string, file: File, uuid?: string, createdAt?: string, name?: string, deltaX?: number, deltaY?: number, media?: string, sopInstanceUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dicom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDicom(study, file, uuid, createdAt, name, deltaX, deltaY, media, sopInstanceUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalStudy(study?: Study, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalStudy(study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Scan} [scan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScan(scan?: Scan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScan(scan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStudy(study?: Study, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStudy(study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(token?: Token, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTokenRefresh(tokenRefresh?: TokenRefresh, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTokenRefresh(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(register?: Register, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Register>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(register, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebhook(webhook?: Webhook, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebhook(webhook, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createheartlabWebhookRequestHandler(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createheartlabWebhookRequestHandler(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyDicom(uuid: string, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyDicom(uuid, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyScan(uuid: string, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyScan(uuid, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyWebhook(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyWebhook(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDetailedStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDetailedStudys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDetailedStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDicoms(page?: number, pageSize?: number, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDicoms200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDicoms(page, pageSize, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInternalStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInternalStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrivateOpenAPICompatibleReports(page?: number, pageSize?: number, study?: string, scan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicOpenAPICompatibleReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrivateOpenAPICompatibleReports(page, pageSize, study, scan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublicOpenAPICompatibleReports(page?: number, pageSize?: number, study?: string, scan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPublicOpenAPICompatibleReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublicOpenAPICompatibleReports(page, pageSize, study, scan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRetryExtractions(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRetryExtractions(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listScans(page?: number, pageSize?: number, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListScans200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listScans(page, pageSize, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
         *
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllStudies(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListStudys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllStudies(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTokens(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTokens200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTokens(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTools(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTools200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTools(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWebhooks(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWebhooks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWebhooks(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listhtmlReports(reportUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listhtmlReports(reportUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, detailedStudy?: DetailedStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedStudy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, detailedStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateToken(id: string, token?: Token, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateToken(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateWebhook(uuid: string, webhook?: Webhook, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateWebhook(uuid, webhook, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedStudy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveDicom(uuid: string, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dicom>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveDicom(uuid, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveEmpty(reportUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveEmpty(reportUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} inferenceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInferenceReport(inferenceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInferenceReport(inferenceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePrivateOpenAPICompatibleReport(uuid: string, study?: string, scan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateOpenAPIReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePrivateOpenAPICompatibleReport(uuid, study, scan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePublicOpenAPICompatibleReport(uuid: string, study?: string, scan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicOpenAPIReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePublicOpenAPICompatibleReport(uuid, study, scan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveRetryExtraction(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveRetryExtraction(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveScan(uuid: string, study?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveScan(uuid, study, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Study>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveTestWebhook(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveTestWebhook(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveTool(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveTool(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveVersionIdentifier(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveVersionIdentifier(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveWebhook(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveWebhook(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateToken(id: string, token?: Token, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateToken(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDetailedStudy(detailedStudy?: DetailedStudy, options?: any): AxiosPromise<DetailedStudy> {
            return localVarFp.createDetailedStudy(detailedStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} study 
         * @param {File} file 
         * @param {string} [uuid] 
         * @param {string} [createdAt] 
         * @param {string} [name] 
         * @param {number} [deltaX] 
         * @param {number} [deltaY] 
         * @param {string} [media] 
         * @param {string} [sopInstanceUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDicom(study: string, file: File, uuid?: string, createdAt?: string, name?: string, deltaX?: number, deltaY?: number, media?: string, sopInstanceUid?: string, options?: any): AxiosPromise<Dicom> {
            return localVarFp.createDicom(study, file, uuid, createdAt, name, deltaX, deltaY, media, sopInstanceUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalStudy(study?: Study, options?: any): AxiosPromise<Study> {
            return localVarFp.createInternalStudy(study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Scan} [scan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScan(scan?: Scan, options?: any): AxiosPromise<Scan> {
            return localVarFp.createScan(scan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStudy(study?: Study, options?: any): AxiosPromise<Study> {
            return localVarFp.createStudy(study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(token?: Token, options?: any): AxiosPromise<Token> {
            return localVarFp.createToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenRefresh(tokenRefresh?: TokenRefresh, options?: any): AxiosPromise<TokenRefresh> {
            return localVarFp.createTokenRefresh(tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(register?: Register, options?: any): AxiosPromise<Register> {
            return localVarFp.createUser(register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook(webhook?: Webhook, options?: any): AxiosPromise<Webhook> {
            return localVarFp.createWebhook(webhook, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createheartlabWebhookRequestHandler(body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.createheartlabWebhookRequestHandler(body, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyDicom(uuid: string, study?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyDicom(uuid, study, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyScan(uuid: string, study?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyScan(uuid, study, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyToken(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * override delete behavior
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyWebhook(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyWebhook(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetailedStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<ListDetailedStudys200Response> {
            return localVarFp.listDetailedStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDicoms(page?: number, pageSize?: number, study?: string, options?: any): AxiosPromise<ListDicoms200Response> {
            return localVarFp.listDicoms(page, pageSize, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInternalStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<ListStudys200Response> {
            return localVarFp.listInternalStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrivateOpenAPICompatibleReports(page?: number, pageSize?: number, study?: string, scan?: string, options?: any): AxiosPromise<ListPublicOpenAPICompatibleReports200Response> {
            return localVarFp.listPrivateOpenAPICompatibleReports(page, pageSize, study, scan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublicOpenAPICompatibleReports(page?: number, pageSize?: number, study?: string, scan?: string, options?: any): AxiosPromise<ListPublicOpenAPICompatibleReports200Response> {
            return localVarFp.listPublicOpenAPICompatibleReports(page, pageSize, study, scan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRetryExtractions(page?: number, pageSize?: number, options?: any): AxiosPromise<ListStudys200Response> {
            return localVarFp.listRetryExtractions(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listScans(page?: number, pageSize?: number, study?: string, options?: any): AxiosPromise<ListScans200Response> {
            return localVarFp.listScans(page, pageSize, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudys(page?: number, pageSize?: number, q?: string, name?: string, uuid?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<ListStudys200Response> {
            return localVarFp.listStudys(page, pageSize, q, name, uuid, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokens(page?: number, pageSize?: number, options?: any): AxiosPromise<ListTokens200Response> {
            return localVarFp.listTokens(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTools(page?: number, pageSize?: number, options?: any): AxiosPromise<ListTools200Response> {
            return localVarFp.listTools(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebhooks(page?: number, pageSize?: number, options?: any): AxiosPromise<ListWebhooks200Response> {
            return localVarFp.listWebhooks(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listhtmlReports(reportUuid: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.listhtmlReports(reportUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {DetailedStudy} [detailedStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, detailedStudy?: DetailedStudy, options?: any): AxiosPromise<DetailedStudy> {
            return localVarFp.partialUpdateDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, detailedStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options?: any): AxiosPromise<Study> {
            return localVarFp.partialUpdateInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {Study} [study] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, study?: Study, options?: any): AxiosPromise<Study> {
            return localVarFp.partialUpdateStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateToken(id: string, token?: Token, options?: any): AxiosPromise<Token> {
            return localVarFp.partialUpdateToken(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateUser(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.partialUpdateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {Webhook} [webhook] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateWebhook(uuid: string, webhook?: Webhook, options?: any): AxiosPromise<Webhook> {
            return localVarFp.partialUpdateWebhook(uuid, webhook, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDetailedStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<DetailedStudy> {
            return localVarFp.retrieveDetailedStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDicom(uuid: string, study?: string, options?: any): AxiosPromise<Dicom> {
            return localVarFp.retrieveDicom(uuid, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEmpty(reportUuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveEmpty(reportUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inferenceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInferenceReport(inferenceUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.retrieveInferenceReport(inferenceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInternalStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<Study> {
            return localVarFp.retrieveInternalStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePrivateOpenAPICompatibleReport(uuid: string, study?: string, scan?: string, options?: any): AxiosPromise<PrivateOpenAPIReport> {
            return localVarFp.retrievePrivateOpenAPICompatibleReport(uuid, study, scan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {string} [scan] scan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePublicOpenAPICompatibleReport(uuid: string, study?: string, scan?: string, options?: any): AxiosPromise<PublicOpenAPIReport> {
            return localVarFp.retrievePublicOpenAPICompatibleReport(uuid, study, scan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveRetryExtraction(uuid: string, options?: any): AxiosPromise<Study> {
            return localVarFp.retrieveRetryExtraction(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [study] study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveScan(uuid: string, study?: string, options?: any): AxiosPromise<Scan> {
            return localVarFp.retrieveScan(uuid, study, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {string} [q] search
         * @param {string} [name] name
         * @param {string} [uuid2] uuid
         * @param {string} [createdAtGte] created_at__gte
         * @param {string} [createdAtLte] created_at__lte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStudy(uuid: string, q?: string, name?: string, uuid2?: string, createdAtGte?: string, createdAtLte?: string, options?: any): AxiosPromise<Study> {
            return localVarFp.retrieveStudy(uuid, q, name, uuid2, createdAtGte, createdAtLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTestWebhook(uuid: string, options?: any): AxiosPromise<object> {
            return localVarFp.retrieveTestWebhook(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveToken(id: string, options?: any): AxiosPromise<Token> {
            return localVarFp.retrieveToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this tool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTool(id: string, options?: any): AxiosPromise<Tool> {
            return localVarFp.retrieveTool(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUser(options?: any): AxiosPromise<User> {
            return localVarFp.retrieveUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVersionIdentifier(options?: any): AxiosPromise<VersionIdentifier> {
            return localVarFp.retrieveVersionIdentifier(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWebhook(uuid: string, options?: any): AxiosPromise<Webhook> {
            return localVarFp.retrieveWebhook(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Token} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken(id: string, token?: Token, options?: any): AxiosPromise<Token> {
            return localVarFp.updateToken(id, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDetailedStudy operation in ApiApi.
 * @export
 * @interface ApiApiCreateDetailedStudyRequest
 */
export interface ApiApiCreateDetailedStudyRequest {
    /**
     * 
     * @type {DetailedStudy}
     * @memberof ApiApiCreateDetailedStudy
     */
    readonly detailedStudy?: DetailedStudy
}

/**
 * Request parameters for createDicom operation in ApiApi.
 * @export
 * @interface ApiApiCreateDicomRequest
 */
export interface ApiApiCreateDicomRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly study: string

    /**
     * 
     * @type {File}
     * @memberof ApiApiCreateDicom
     */
    readonly file: File

    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly uuid?: string

    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly createdAt?: string

    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ApiApiCreateDicom
     */
    readonly deltaX?: number

    /**
     * 
     * @type {number}
     * @memberof ApiApiCreateDicom
     */
    readonly deltaY?: number

    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly media?: string

    /**
     * 
     * @type {string}
     * @memberof ApiApiCreateDicom
     */
    readonly sopInstanceUid?: string
}

/**
 * Request parameters for createInternalStudy operation in ApiApi.
 * @export
 * @interface ApiApiCreateInternalStudyRequest
 */
export interface ApiApiCreateInternalStudyRequest {
    /**
     * 
     * @type {Study}
     * @memberof ApiApiCreateInternalStudy
     */
    readonly study?: Study
}

/**
 * Request parameters for createScan operation in ApiApi.
 * @export
 * @interface ApiApiCreateScanRequest
 */
export interface ApiApiCreateScanRequest {
    /**
     * 
     * @type {Scan}
     * @memberof ApiApiCreateScan
     */
    readonly scan?: Scan
}

/**
 * Request parameters for createStudy operation in ApiApi.
 * @export
 * @interface ApiApiCreateStudyRequest
 */
export interface ApiApiCreateStudyRequest {
    /**
     * 
     * @type {Study}
     * @memberof ApiApiCreateStudy
     */
    readonly study?: Study
}

/**
 * Request parameters for createToken operation in ApiApi.
 * @export
 * @interface ApiApiCreateTokenRequest
 */
export interface ApiApiCreateTokenRequest {
    /**
     * 
     * @type {Token}
     * @memberof ApiApiCreateToken
     */
    readonly token?: Token
}

/**
 * Request parameters for createTokenRefresh operation in ApiApi.
 * @export
 * @interface ApiApiCreateTokenRefreshRequest
 */
export interface ApiApiCreateTokenRefreshRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof ApiApiCreateTokenRefresh
     */
    readonly tokenRefresh?: TokenRefresh
}

/**
 * Request parameters for createUser operation in ApiApi.
 * @export
 * @interface ApiApiCreateUserRequest
 */
export interface ApiApiCreateUserRequest {
    /**
     * 
     * @type {Register}
     * @memberof ApiApiCreateUser
     */
    readonly register?: Register
}

/**
 * Request parameters for createWebhook operation in ApiApi.
 * @export
 * @interface ApiApiCreateWebhookRequest
 */
export interface ApiApiCreateWebhookRequest {
    /**
     * 
     * @type {Webhook}
     * @memberof ApiApiCreateWebhook
     */
    readonly webhook?: Webhook
}

/**
 * Request parameters for createheartlabWebhookRequestHandler operation in ApiApi.
 * @export
 * @interface ApiApiCreateheartlabWebhookRequestHandlerRequest
 */
export interface ApiApiCreateheartlabWebhookRequestHandlerRequest {
    /**
     * 
     * @type {any}
     * @memberof ApiApiCreateheartlabWebhookRequestHandler
     */
    readonly body?: any
}

/**
 * Request parameters for destroyDetailedStudy operation in ApiApi.
 * @export
 * @interface ApiApiDestroyDetailedStudyRequest
 */
export interface ApiApiDestroyDetailedStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiDestroyDetailedStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for destroyDicom operation in ApiApi.
 * @export
 * @interface ApiApiDestroyDicomRequest
 */
export interface ApiApiDestroyDicomRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyDicom
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiDestroyDicom
     */
    readonly study?: string
}

/**
 * Request parameters for destroyInternalStudy operation in ApiApi.
 * @export
 * @interface ApiApiDestroyInternalStudyRequest
 */
export interface ApiApiDestroyInternalStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiDestroyInternalStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for destroyScan operation in ApiApi.
 * @export
 * @interface ApiApiDestroyScanRequest
 */
export interface ApiApiDestroyScanRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyScan
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiDestroyScan
     */
    readonly study?: string
}

/**
 * Request parameters for destroyStudy operation in ApiApi.
 * @export
 * @interface ApiApiDestroyStudyRequest
 */
export interface ApiApiDestroyStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiDestroyStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for destroyToken operation in ApiApi.
 * @export
 * @interface ApiApiDestroyTokenRequest
 */
export interface ApiApiDestroyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyToken
     */
    readonly id: string
}

/**
 * Request parameters for destroyWebhook operation in ApiApi.
 * @export
 * @interface ApiApiDestroyWebhookRequest
 */
export interface ApiApiDestroyWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiDestroyWebhook
     */
    readonly uuid: string
}

/**
 * Request parameters for listDetailedStudys operation in ApiApi.
 * @export
 * @interface ApiApiListDetailedStudysRequest
 */
export interface ApiApiListDetailedStudysRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListDetailedStudys
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListDetailedStudys
     */
    readonly pageSize?: number

    /**
     * search
     * @type {string}
     * @memberof ApiApiListDetailedStudys
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiListDetailedStudys
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiListDetailedStudys
     */
    readonly uuid?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiListDetailedStudys
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiListDetailedStudys
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for listDicoms operation in ApiApi.
 * @export
 * @interface ApiApiListDicomsRequest
 */
export interface ApiApiListDicomsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListDicoms
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListDicoms
     */
    readonly pageSize?: number

    /**
     * study
     * @type {string}
     * @memberof ApiApiListDicoms
     */
    readonly study?: string
}

/**
 * Request parameters for listInternalStudys operation in ApiApi.
 * @export
 * @interface ApiApiListInternalStudysRequest
 */
export interface ApiApiListInternalStudysRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListInternalStudys
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListInternalStudys
     */
    readonly pageSize?: number

    /**
     * search
     * @type {string}
     * @memberof ApiApiListInternalStudys
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiListInternalStudys
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiListInternalStudys
     */
    readonly uuid?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiListInternalStudys
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiListInternalStudys
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for listPrivateOpenAPICompatibleReports operation in ApiApi.
 * @export
 * @interface ApiApiListPrivateOpenAPICompatibleReportsRequest
 */
export interface ApiApiListPrivateOpenAPICompatibleReportsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListPrivateOpenAPICompatibleReports
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListPrivateOpenAPICompatibleReports
     */
    readonly pageSize?: number

    /**
     * study
     * @type {string}
     * @memberof ApiApiListPrivateOpenAPICompatibleReports
     */
    readonly study?: string

    /**
     * scan
     * @type {string}
     * @memberof ApiApiListPrivateOpenAPICompatibleReports
     */
    readonly scan?: string
}

/**
 * Request parameters for listPublicOpenAPICompatibleReports operation in ApiApi.
 * @export
 * @interface ApiApiListPublicOpenAPICompatibleReportsRequest
 */
export interface ApiApiListPublicOpenAPICompatibleReportsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListPublicOpenAPICompatibleReports
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListPublicOpenAPICompatibleReports
     */
    readonly pageSize?: number

    /**
     * study
     * @type {string}
     * @memberof ApiApiListPublicOpenAPICompatibleReports
     */
    readonly study?: string

    /**
     * scan
     * @type {string}
     * @memberof ApiApiListPublicOpenAPICompatibleReports
     */
    readonly scan?: string
}

/**
 * Request parameters for listRetryExtractions operation in ApiApi.
 * @export
 * @interface ApiApiListRetryExtractionsRequest
 */
export interface ApiApiListRetryExtractionsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListRetryExtractions
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListRetryExtractions
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listScans operation in ApiApi.
 * @export
 * @interface ApiApiListScansRequest
 */
export interface ApiApiListScansRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListScans
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListScans
     */
    readonly pageSize?: number

    /**
     * study
     * @type {string}
     * @memberof ApiApiListScans
     */
    readonly study?: string
}

/**
 * Request parameters for listStudys operation in ApiApi.
 * @export
 * @interface ApiApiListStudysRequest
 */
export interface ApiApiListStudysRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListStudys
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListStudys
     */
    readonly pageSize?: number

    /**
     * search
     * @type {string}
     * @memberof ApiApiListStudys
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiListStudys
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiListStudys
     */
    readonly uuid?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiListStudys
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiListStudys
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for listTokens operation in ApiApi.
 * @export
 * @interface ApiApiListTokensRequest
 */
export interface ApiApiListTokensRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListTokens
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListTokens
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTools operation in ApiApi.
 * @export
 * @interface ApiApiListToolsRequest
 */
export interface ApiApiListToolsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListTools
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListTools
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listWebhooks operation in ApiApi.
 * @export
 * @interface ApiApiListWebhooksRequest
 */
export interface ApiApiListWebhooksRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApiApiListWebhooks
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApiApiListWebhooks
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listhtmlReports operation in ApiApi.
 * @export
 * @interface ApiApiListhtmlReportsRequest
 */
export interface ApiApiListhtmlReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiListhtmlReports
     */
    readonly reportUuid: string
}

/**
 * Request parameters for partialUpdateDetailedStudy operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateDetailedStudyRequest
 */
export interface ApiApiPartialUpdateDetailedStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly createdAtLte?: string

    /**
     * 
     * @type {DetailedStudy}
     * @memberof ApiApiPartialUpdateDetailedStudy
     */
    readonly detailedStudy?: DetailedStudy
}

/**
 * Request parameters for partialUpdateInternalStudy operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateInternalStudyRequest
 */
export interface ApiApiPartialUpdateInternalStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly createdAtLte?: string

    /**
     * 
     * @type {Study}
     * @memberof ApiApiPartialUpdateInternalStudy
     */
    readonly study?: Study
}

/**
 * Request parameters for partialUpdateStudy operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateStudyRequest
 */
export interface ApiApiPartialUpdateStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly createdAtLte?: string

    /**
     * 
     * @type {Study}
     * @memberof ApiApiPartialUpdateStudy
     */
    readonly study?: Study
}

/**
 * Request parameters for partialUpdateToken operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateTokenRequest
 */
export interface ApiApiPartialUpdateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiPartialUpdateToken
     */
    readonly id: string

    /**
     * 
     * @type {Token}
     * @memberof ApiApiPartialUpdateToken
     */
    readonly token?: Token
}

/**
 * Request parameters for partialUpdateUser operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateUserRequest
 */
export interface ApiApiPartialUpdateUserRequest {
    /**
     * 
     * @type {User}
     * @memberof ApiApiPartialUpdateUser
     */
    readonly user?: User
}

/**
 * Request parameters for partialUpdateWebhook operation in ApiApi.
 * @export
 * @interface ApiApiPartialUpdateWebhookRequest
 */
export interface ApiApiPartialUpdateWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiPartialUpdateWebhook
     */
    readonly uuid: string

    /**
     * 
     * @type {Webhook}
     * @memberof ApiApiPartialUpdateWebhook
     */
    readonly webhook?: Webhook
}

/**
 * Request parameters for retrieveDetailedStudy operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveDetailedStudyRequest
 */
export interface ApiApiRetrieveDetailedStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiRetrieveDetailedStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for retrieveDicom operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveDicomRequest
 */
export interface ApiApiRetrieveDicomRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveDicom
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiRetrieveDicom
     */
    readonly study?: string
}

/**
 * Request parameters for retrieveEmpty operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveEmptyRequest
 */
export interface ApiApiRetrieveEmptyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveEmpty
     */
    readonly reportUuid: string
}

/**
 * Request parameters for retrieveInferenceReport operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveInferenceReportRequest
 */
export interface ApiApiRetrieveInferenceReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveInferenceReport
     */
    readonly inferenceUuid: string
}

/**
 * Request parameters for retrieveInternalStudy operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveInternalStudyRequest
 */
export interface ApiApiRetrieveInternalStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiRetrieveInternalStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for retrievePrivateOpenAPICompatibleReport operation in ApiApi.
 * @export
 * @interface ApiApiRetrievePrivateOpenAPICompatibleReportRequest
 */
export interface ApiApiRetrievePrivateOpenAPICompatibleReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrievePrivateOpenAPICompatibleReport
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiRetrievePrivateOpenAPICompatibleReport
     */
    readonly study?: string

    /**
     * scan
     * @type {string}
     * @memberof ApiApiRetrievePrivateOpenAPICompatibleReport
     */
    readonly scan?: string
}

/**
 * Request parameters for retrievePublicOpenAPICompatibleReport operation in ApiApi.
 * @export
 * @interface ApiApiRetrievePublicOpenAPICompatibleReportRequest
 */
export interface ApiApiRetrievePublicOpenAPICompatibleReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrievePublicOpenAPICompatibleReport
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiRetrievePublicOpenAPICompatibleReport
     */
    readonly study?: string

    /**
     * scan
     * @type {string}
     * @memberof ApiApiRetrievePublicOpenAPICompatibleReport
     */
    readonly scan?: string
}

/**
 * Request parameters for retrieveRetryExtraction operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveRetryExtractionRequest
 */
export interface ApiApiRetrieveRetryExtractionRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveRetryExtraction
     */
    readonly uuid: string
}

/**
 * Request parameters for retrieveScan operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveScanRequest
 */
export interface ApiApiRetrieveScanRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveScan
     */
    readonly uuid: string

    /**
     * study
     * @type {string}
     * @memberof ApiApiRetrieveScan
     */
    readonly study?: string
}

/**
 * Request parameters for retrieveStudy operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveStudyRequest
 */
export interface ApiApiRetrieveStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly uuid: string

    /**
     * search
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly q?: string

    /**
     * name
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly name?: string

    /**
     * uuid
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly uuid2?: string

    /**
     * created_at__gte
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly createdAtGte?: string

    /**
     * created_at__lte
     * @type {string}
     * @memberof ApiApiRetrieveStudy
     */
    readonly createdAtLte?: string
}

/**
 * Request parameters for retrieveTestWebhook operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveTestWebhookRequest
 */
export interface ApiApiRetrieveTestWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveTestWebhook
     */
    readonly uuid: string
}

/**
 * Request parameters for retrieveToken operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveTokenRequest
 */
export interface ApiApiRetrieveTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveToken
     */
    readonly id: string
}

/**
 * Request parameters for retrieveTool operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveToolRequest
 */
export interface ApiApiRetrieveToolRequest {
    /**
     * A unique integer value identifying this tool.
     * @type {string}
     * @memberof ApiApiRetrieveTool
     */
    readonly id: string
}

/**
 * Request parameters for retrieveWebhook operation in ApiApi.
 * @export
 * @interface ApiApiRetrieveWebhookRequest
 */
export interface ApiApiRetrieveWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiRetrieveWebhook
     */
    readonly uuid: string
}

/**
 * Request parameters for updateToken operation in ApiApi.
 * @export
 * @interface ApiApiUpdateTokenRequest
 */
export interface ApiApiUpdateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiApiUpdateToken
     */
    readonly id: string

    /**
     * 
     * @type {Token}
     * @memberof ApiApiUpdateToken
     */
    readonly token?: Token
}

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @param {ApiApiCreateDetailedStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createDetailedStudy(requestParameters: ApiApiCreateDetailedStudyRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createDetailedStudy(requestParameters.detailedStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateDicomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createDicom(requestParameters: ApiApiCreateDicomRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createDicom(requestParameters.study, requestParameters.file, requestParameters.uuid, requestParameters.createdAt, requestParameters.name, requestParameters.deltaX, requestParameters.deltaY, requestParameters.media, requestParameters.sopInstanceUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateInternalStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createInternalStudy(requestParameters: ApiApiCreateInternalStudyRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createInternalStudy(requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateScanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createScan(requestParameters: ApiApiCreateScanRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createScan(requestParameters.scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createStudy(requestParameters: ApiApiCreateStudyRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createStudy(requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createToken(requestParameters: ApiApiCreateTokenRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createToken(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateTokenRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createTokenRefresh(requestParameters: ApiApiCreateTokenRefreshRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createTokenRefresh(requestParameters.tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createUser(requestParameters: ApiApiCreateUserRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createUser(requestParameters.register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createWebhook(requestParameters: ApiApiCreateWebhookRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createWebhook(requestParameters.webhook, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiCreateheartlabWebhookRequestHandlerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createheartlabWebhookRequestHandler(requestParameters: ApiApiCreateheartlabWebhookRequestHandlerRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createheartlabWebhookRequestHandler(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyDetailedStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyDetailedStudy(requestParameters: ApiApiDestroyDetailedStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyDetailedStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyDicomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyDicom(requestParameters: ApiApiDestroyDicomRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyDicom(requestParameters.uuid, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyInternalStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyInternalStudy(requestParameters: ApiApiDestroyInternalStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyInternalStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyScanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyScan(requestParameters: ApiApiDestroyScanRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyScan(requestParameters.uuid, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyStudy(requestParameters: ApiApiDestroyStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiDestroyTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyToken(requestParameters: ApiApiDestroyTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * override delete behavior
     * @param {ApiApiDestroyWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public destroyWebhook(requestParameters: ApiApiDestroyWebhookRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).destroyWebhook(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListDetailedStudysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listDetailedStudys(requestParameters: ApiApiListDetailedStudysRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listDetailedStudys(requestParameters.page, requestParameters.pageSize, requestParameters.q, requestParameters.name, requestParameters.uuid, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListDicomsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listDicoms(requestParameters: ApiApiListDicomsRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listDicoms(requestParameters.page, requestParameters.pageSize, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListInternalStudysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listInternalStudys(requestParameters: ApiApiListInternalStudysRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listInternalStudys(requestParameters.page, requestParameters.pageSize, requestParameters.q, requestParameters.name, requestParameters.uuid, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListPrivateOpenAPICompatibleReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listPrivateOpenAPICompatibleReports(requestParameters: ApiApiListPrivateOpenAPICompatibleReportsRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listPrivateOpenAPICompatibleReports(requestParameters.page, requestParameters.pageSize, requestParameters.study, requestParameters.scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListPublicOpenAPICompatibleReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listPublicOpenAPICompatibleReports(requestParameters: ApiApiListPublicOpenAPICompatibleReportsRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listPublicOpenAPICompatibleReports(requestParameters.page, requestParameters.pageSize, requestParameters.study, requestParameters.scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListRetryExtractionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listRetryExtractions(requestParameters: ApiApiListRetryExtractionsRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listRetryExtractions(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListScansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listScans(requestParameters: ApiApiListScansRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listScans(requestParameters.page, requestParameters.pageSize, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListStudysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listStudys(requestParameters: ApiApiListStudysRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listStudys(requestParameters.page, requestParameters.pageSize, requestParameters.q, requestParameters.name, requestParameters.uuid, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ApiApiListStudysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listAllStudies(requestParameters: ApiApiListStudysRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listAllStudies(requestParameters.page, requestParameters.pageSize, requestParameters.q, requestParameters.name, requestParameters.uuid, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listTokens(requestParameters: ApiApiListTokensRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listTokens(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListToolsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listTools(requestParameters: ApiApiListToolsRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listTools(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListWebhooksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listWebhooks(requestParameters: ApiApiListWebhooksRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listWebhooks(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiListhtmlReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public listhtmlReports(requestParameters: ApiApiListhtmlReportsRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).listhtmlReports(requestParameters.reportUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateDetailedStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateDetailedStudy(requestParameters: ApiApiPartialUpdateDetailedStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateDetailedStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, requestParameters.detailedStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateInternalStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateInternalStudy(requestParameters: ApiApiPartialUpdateInternalStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateInternalStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateStudy(requestParameters: ApiApiPartialUpdateStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateToken(requestParameters: ApiApiPartialUpdateTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateToken(requestParameters.id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateUser(requestParameters: ApiApiPartialUpdateUserRequest = {}, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateUser(requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiPartialUpdateWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public partialUpdateWebhook(requestParameters: ApiApiPartialUpdateWebhookRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).partialUpdateWebhook(requestParameters.uuid, requestParameters.webhook, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveDetailedStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveDetailedStudy(requestParameters: ApiApiRetrieveDetailedStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveDetailedStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveDicomRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveDicom(requestParameters: ApiApiRetrieveDicomRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveDicom(requestParameters.uuid, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveEmptyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveEmpty(requestParameters: ApiApiRetrieveEmptyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveEmpty(requestParameters.reportUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveInferenceReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveInferenceReport(requestParameters: ApiApiRetrieveInferenceReportRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveInferenceReport(requestParameters.inferenceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveInternalStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveInternalStudy(requestParameters: ApiApiRetrieveInternalStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveInternalStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrievePrivateOpenAPICompatibleReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrievePrivateOpenAPICompatibleReport(requestParameters: ApiApiRetrievePrivateOpenAPICompatibleReportRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrievePrivateOpenAPICompatibleReport(requestParameters.uuid, requestParameters.study, requestParameters.scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrievePublicOpenAPICompatibleReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrievePublicOpenAPICompatibleReport(requestParameters: ApiApiRetrievePublicOpenAPICompatibleReportRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrievePublicOpenAPICompatibleReport(requestParameters.uuid, requestParameters.study, requestParameters.scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveRetryExtractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveRetryExtraction(requestParameters: ApiApiRetrieveRetryExtractionRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveRetryExtraction(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveScanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveScan(requestParameters: ApiApiRetrieveScanRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveScan(requestParameters.uuid, requestParameters.study, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveStudyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveStudy(requestParameters: ApiApiRetrieveStudyRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveStudy(requestParameters.uuid, requestParameters.q, requestParameters.name, requestParameters.uuid2, requestParameters.createdAtGte, requestParameters.createdAtLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveTestWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveTestWebhook(requestParameters: ApiApiRetrieveTestWebhookRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveTestWebhook(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveToken(requestParameters: ApiApiRetrieveTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveToolRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveTool(requestParameters: ApiApiRetrieveToolRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveTool(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveUser(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveVersionIdentifier(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveVersionIdentifier(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiRetrieveWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public retrieveWebhook(requestParameters: ApiApiRetrieveWebhookRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).retrieveWebhook(requestParameters.uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiApiUpdateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateToken(requestParameters: ApiApiUpdateTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateToken(requestParameters.id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}


