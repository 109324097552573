import React, { useState, useEffect, FC, useRef, useCallback } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FormControl, Input, IconButton, Button, TextField, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { parseTime } from "../../utils/time";
import { Preloader } from "../../components/preloader";
import { EmptyData } from "../../components/emptyData";
import styles from "./studiesCreate.module.css";
import { apiClient } from "../../api/base";
import { Dicom, Scan, Study, StudySexEnum } from "../../api/generated_api";
import AlertDialog from "../../components/Dialog";
import { Uploader } from "../../components/uploader";
import { FormValues, StudyInputFields } from "./studyInputFields";
import { useForm } from "react-hook-form";

interface studies {
  uuid: string;
  created_at: string;
  name: string;
}

interface results {
  uuid: string;
  created_at: Date;
  study: string;
  file: string;
  name: string;
}

interface dicoms {
  count: number;
  next?: any;
  previous?: any;
  results: results[];
}

interface scan {
  uuid: string;
  created_at: Date;
  study: string;
  report: string;
  status: string;
  start_inference_at: Date;
  end_inference_at: Date;
  total_inference_time: string;
  number_of_available_dicoms?: any;
  number_of_dicoms_scanned: number;
  state: number;
  version: number;
}

interface scans {
  count: number;
  next?: any;
  previous?: any;
  results: scan[];
}

interface IProps {
  isSuperuser: boolean;
}

export const StudiesCreate: FC<IProps> = (props) => {
  const {isSuperuser} = props;
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [study, setStudy] = useState<Study>();
  const [studyLoading, setStudyLoading] = useState<boolean>(true);
  const [scans, setScans] = useState<Scan[]>([]);
  const [scansLoading, setScansLoading] = useState<boolean>(true);
  const [dicoms, setDicoms] = useState<Dicom[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const [dicomsLoading, setDicomsLoading] = useState<boolean>(true);
  const [dicomDeleting, setDicomDeleting] = useState<boolean>(false);
  const inputEl = useRef<any>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let debounceTimer: any;

  if (id === undefined) {
    navigate("/studies");
  }

  const { handleSubmit, control, reset, trigger } = useForm<FormValues>({
    mode: "onChange",
  });

  useEffect(() => {
    id &&
      apiClient
        .retrieveStudy({ uuid: id})
        .then((res) => {
          reset(res.data);
          setStudy(res.data);
        })
        .catch(() => {
          enqueueSnackbar("Studies failed to load", { variant: "error" });
        })
        .finally(() => {
          setStudyLoading(false);
        });
  }, [id]);

  const loadDicoms = () => {
    id &&
      apiClient
        .listDicoms({ study: id })
        .then((res) => {
          res?.data?.results && setDicoms(res?.data?.results);
        })
        .catch(() => {
          enqueueSnackbar("Dicoms failed to load", { variant: "error" });
        })
        .finally(() => {
          setDicomsLoading(false);
        });
  };

  useEffect(() => {
    loadDicoms();
  }, [id, setDicoms]);

  useEffect(() => {
    const MILISECONDS = 1000;
    const interval = setInterval(() => {
      if (shouldReload) {
        reloadScans();
      }
    }, 10 * MILISECONDS);

    return () => clearInterval(interval);
  });

  const reloadScans = () => {
    id &&
      apiClient
        .listScans({ study: id })
        .then((res) => {
          res?.data?.results && setScans(res?.data?.results);

          if (res?.data?.results?.some((scan) => scan.status !== "COMPLETED")) {
            setShouldReload(true);
          }
        })
        .catch(() => {
          enqueueSnackbar("Scans failed to load", { variant: "error" });
        })
        .finally(() => {
          setScansLoading(false);
        });
  };

  useEffect(() => {
    reloadScans();
  }, [id, setScans]);

  const updateStudy = (data: FormValues) => {
    if (id === undefined) {
      return;
    }
    const update_object = {
      uuid: id,
      study: data,
    };

    apiClient
      .partialUpdateStudy(update_object)
      .then(() => {
        enqueueSnackbar("Study has been changed", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Study update failed", { variant: "error" });
      });
  };

  const submitScan = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    id &&
      apiClient
        .createScan({ scan: { study: id } })
        .then((res: any): void => {
          setScans((prevScans: any) => [...prevScans, res?.data]);
          setShouldReload(true);
          enqueueSnackbar("New scan added", { variant: "success" });
        })
        .catch((reason) => {
          enqueueSnackbar(reason.response.data.study.study[0], { variant: "error" });
        });
  };

  const deleteDicomHandler = (id: string) => (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDicomDeleting(true);

    apiClient
      .destroyDicom({ uuid: id })
      .then((res: any): void => {
        setDicoms((prevDicoms: any) => prevDicoms.filter((currentDicom: any) => currentDicom.uuid !== id));
        enqueueSnackbar("Dicom has been removed", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Dicom was not deleted", { variant: "error" });
      })
      .finally(() => {
        setDicomDeleting(false);
      });
  };

  const deleteStudyHandler = () => {
    id &&
      apiClient
        .destroyStudy({ uuid: id })
        .then((res) => {
          enqueueSnackbar("Study has been deleted", { variant: "success" });
          navigate("/studies");
        })
        .catch(() => {
          enqueueSnackbar("Study was not deleted", { variant: "error" });
        });
  };

  const secondsToHms = (d: number) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  };

  if (id === undefined) {
    return <></>;
  }

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <div className={styles.studyWrapper}>
          {studyLoading ? (
            <Preloader inner />
          ) : (
            <>
              <div onBlur={handleSubmit(updateStudy)}>
                <StudyInputFields control={control} />
              </div>

              <br />
              <p>
                <span className={styles.title}>ID</span>
              </p>
              <p>{study?.uuid || "-"}</p>
              <br />
              <p>
                <span className={styles.title}>Date Created</span>
              </p>
              <p>{parseTime(study?.created_at)}</p>
              <br />
              <p>
                <Button color={"error"} variant="contained" onClick={() => setDeleteModalOpen(true)}>
                  Delete
                </Button>
              </p>
              <br />
            </>
          )}
        </div>
        <AlertDialog
          title={"Delete study?"}
          text={"Are you sure you want to delete this study?"}
          handleAccept={deleteStudyHandler}
          open={deleteModalOpen}
          handleClose={setDeleteModalOpen}
        />
        <>
          <div className={styles.diconsWrapper}>
            <p>
              <span className={styles.title}>Dicoms</span>
            </p>
            <p>Drag and drop a file or upload one from your computer. Press show inferences to read the image.</p>
            <div className={styles.whiteContainer}>
              {dicomsLoading ? (
                <Preloader inner />
              ) : (
                <>
                  <ul className={styles.listDicoms}>
                    {dicoms.length ? (
                      <>
                        {dicoms.map((dicomElement: any, index: number) => {
                          return (
                            <li key={index} className={styles.listItemDicoms}>
                              <span>{dicomElement.name}</span>
                              <IconButton
                                data-cy={"delete-dicom"}
                                disabled={dicomDeleting}
                                onClick={deleteDicomHandler(dicomElement.uuid)}
                                aria-label="delete"
                                size="small"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <EmptyData />
                    )}
                    <li className={styles.listItemDicoms}>
                      <span></span>
                    </li>
                  </ul>
                </>
              )}
              <Uploader id={id} onLoad={loadDicoms} />
            </div>
          </div>
          <br />

          <br />
          <p>
            <span className={styles.title}>Scans</span>
          </p>
          <p>Scans will be used to generate a report.</p>
          <div className={styles.whiteContainer}>
            {scansLoading ? (
              <Preloader inner />
            ) : (
              <>
                {scans.length ? (
                  scans.map((el: any, index: number) => {
                    return (
                      <div key={index} className={styles.scansItem}>
                        {el.status === "COMPLETED" && (
                          <Link className={styles.scansView} to={`/report/${el.report}`}>
                            View Report
                          </Link>
                        )}
                        <div>SCAN ID {el.uuid}</div>
                        <div>Scanned: 1/{el.number_of_dicoms_scanned} Dicoms</div>
                        <div>Created: {parseTime(el.created_at)} </div>
                        <footer className={styles.scansFooter}>
                          <span>Total time: {secondsToHms(el.total_inference_time)}</span>{" "}
                          <span>
                            <span>Status:</span>
                            <span className={styles.complete}> {el?.status}</span>
                          </span>
                        </footer>
                      </div>
                    );
                  })
                ) : (
                  <EmptyData />
                )}
              </>
            )}
            <div className={styles.scansBottom}>
              <Button className={styles.scanBtn} variant="outlined" onClick={submitScan} size="small">
                Start a Scan
              </Button>
            </div>
          </div>
        </>
      </form>
    </div>
  );
};
