import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Grid, InputAdornment, InputBase, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Preloader } from "../../components/preloader";
import { parseTime } from "../../utils/time";
import { EmptyData } from "../../components/emptyData";
import styles from "./allStudies.module.css";
import { apiClient } from "../../api/base";
import { Study } from "../../api/generated_api";

interface studies {
  created_at: string;
  name: string;
  uuid: string;
}

interface studies {
  count: number;
  next?: any;
  previous?: any;
  results: Study[];
}

export const AllStudies = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [studies, setStudies] = useState<Study[]>([]);
  const [count, setCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(24);
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get("currentPage")) || 1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>(searchParams.get("q") || "");
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  let debounceTimer: any;

  useEffect(() => {
    setPaginationLoading(true);
    apiClient
      .listAllStudies({ q: searchValue, page: currentPage, pageSize: pageSize })
      .then((res) => {
        res.data.results && setStudies(res.data.results);
        res.data.count && setCount(res.data.count);
      })
      .catch((err) => {
        enqueueSnackbar("Studies failed to load", { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
        setPaginationLoading(false);
      });
  }, [currentPage, pageSize, searchValue, searchValue]);

  const handlePaginationChange = (event: any, page: number, count: number) => {
    setCurrentPage(page);
  };

  const getSearchStudies = (value: string) => () => {
    setSearchValue(value);
  };

  const debounce = useCallback(
    (
      callback: (value: string) => any,
      time: number,
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSearchValue(event.target.value);
      window.clearTimeout(debounceTimer);
      debounceTimer = window.setTimeout(callback(event.target.value), time);
    },
    []
  );

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <Grid container justifyContent="center" className={styles.wrapper}>
          <Grid item xs={12}>
            <h2 className={styles.mainHeader}>Dashboard</h2>
            <div className={styles.searchWrapper}>
              <InputBase
                className={styles.searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={styles.searchIcon} />
                  </InputAdornment>
                }
                placeholder="Search..."
                value={searchValue}
                onChange={(event) => debounce(getSearchStudies, 600, event)}
              />
            </div>

            <div className={styles.studiesWrapper}>
              <h2 >Studies</h2>
              {paginationLoading ? (
                <Preloader />
              ) : (
                <div className={styles.studiesList}>
                  <Grid container spacing={2} rowSpacing={2} alignItems={"center"} justifyContent={"center"}>
                    {studies?.length ? (
                      studies.map((item, index: number) => {
                        return (
                          <Grid key={index} className={styles.listItem} item xs={12} sm={6} md={3} lg={2.5}>
                            <Link to={`/studies/${item.uuid}`} >
                              <div className={styles.listItemHeader}>{item.name || `Study name ${index + 1}`}</div>
                              <div className={styles.listItemDate}>{parseTime(item.created_at)}</div>
                              <div className={styles.listItemId}>{item.uuid}</div>
                            </Link>
                          </Grid>
                        );
                      })
                    ) : (
                      <EmptyData />
                    )}
                  </Grid>
                </div>
              )}
            </div>
            <div className={styles.paginationWrapper}>
              <span className={styles.showItems}>
                Showing {24 * (currentPage - 1) + 1} - {Math.min(24 * currentPage, count)}/{count} Studies
              </span>
              <Pagination
                count={Math.ceil(count / 24)}
                page={currentPage}
                onChange={(event, page) => handlePaginationChange(event, page, count)}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
